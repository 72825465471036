.donate{
    height: 100%;
    min-height: 100vh;
    background-image: url('../../images/background.png');
    background-size: cover;
    background-position: center;
}

.donate .inner{
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 0 10px;
}

.donate .inner .sub-inner{
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
}

.donate .inner .sub-inner figure{
    width: 100%;
    margin-bottom: 20px;
}

.donate .inner .sub-inner figure img{
    width: 100%;
}

.donate .inner .sub-inner p{
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    font-style: italic;
}