.mapseat{
    width: 500px;
}

.mapseat .title{
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.mapseat .main{
    margin-bottom: 20px;
}

.mapseat .main .stage{
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-bottom: 20px;
}

.mapseat .main .stage::after{
    content: "";
    height: 40px;
    width: 40px;
    background-color: #D9D9D9;
}

.mapseat .main .stage p{
    width: 200px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D9D9D9;
    color: black;
    font-size: 20px;
    font-weight: bold;
    position: relative;
}

.mapseat .main .stage p::after{
    content: "";
    height: 20px;
    width: 38px;
    position: absolute;
    left: -67px;
    bottom: -10px;
    background-color: #D9D9D9;
    transform: rotate(-26deg);
}

.mapseat .main .stage p::before{
    content: "";
    height: 20px;
    width: 38px;
    position: absolute;
    right: -67px;
    bottom: -10px;
    background-color: #D9D9D9;
    transform: rotate(26deg);
}

.mapseat .main .list-row{
    display: flex;
    flex-flow: column;
    gap: 3px;
}

.mapseat .main .list-row .row{
    display: flex;
    height: 12px;
    justify-content: space-between;
}

.mapseat .main .list-row .row:hover .rowseat .seat{
    background-color: #198c07;
}

.mapseat .main .list-row .row.num{
    margin-top: 5px;
}


.mapseat .main .list-row .row .textrow{
    width: 12px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.mapseat .main .list-row .row .rowseat{
    display: flex;
    gap: 55px;
}

.mapseat .main .list-row .row .rowseat .left{
    width: 196px;
    height: 100%;
    background-color: #D9D9D9;
}

.mapseat .main .list-row .row .rowseat .right{
    width: 224px;
    height: 100%;
    background-color: #D9D9D9;
}

.mapseat .main .list-row .row .rowseat ul{
    display: flex;
    list-style: none;
}

.mapseat .main .list-row .row .rowseat .seat{
    width: 14px;
    height: 14px;
    border: 1px solid white;
    background-color: #45BD32;
    transition: background-color ease-in-out 0.25s;
}

.mapseat .main .list-row .row .rowseat .seatnum{
    width: 14px;
    color: white;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.mapseat .note{
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.mapseat .note .item{
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 14px;
}

.mapseat .note .item div{
    height: 14px;
    width: 14px;
    border: 1px solid white;
}

.mapseat .note .item.i1 div{
    background-color: #D9D9D9;
}

.mapseat .note .item.i2 div{
    background-color: #45BD32;
}

.mapseat .note .item.i3 div{
    background-color: #26BEC8
}

.mapseat .listchoice{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.mapseat .listchoice p{
    color: white;
}

.mapseat .listchoice div {
    height: 40px;
    width: 300px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0 10px;
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.mapseat .listchoice div::-webkit-scrollbar{
    height: 5px;
}

.mapseat .listchoice div ul{
    display: flex;
    gap: 5px;
}

.mapseat .listchoice div ul li{
    height: 25px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #26BEC8;
    color: white;
    font-weight: bold;
    transition: background-color ease-in-out 0.25s;
    cursor: pointer;
}

.mapseat .listchoice div ul li:hover{
    background-color: red;
}

.mapseat .wait{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    gap: 10px
}

.mapseat .wait p{
    color: #D9D9D9;
}

@media screen and (max-width: 768px){
    .mapseat{
        max-width: 405px;
        width: 100%;
    }

    .mapseat .main .list-row .row .rowseat{
        gap: 18px;
    }

    .mapseat .main .list-row .row .rowseat .seat {
        width: 12px;
        height: 12px;
    }

    .mapseat .main .list-row .row .rowseat .right {
        width: 192px;
    }

    .mapseat .main .list-row .row .rowseat .left {
        width: 168px;
    }

    .mapseat .main .list-row {
        gap: 2px;
    }

    .mapseat .main .list-row .row .textrow {
        font-size: 10px;
    }

    .mapseat .main .list-row .row .rowseat .seatnum {
        width: 12px;
    }

    .mapseat .note .item div {
        height: 12px;
        width: 12px;
    }
    
    .mapseat .note .item {
        font-size: 12px;
    }

    .mapseat .listchoice p {
        font-size: 14px;
        width: 85px;
    }
}

@media screen and (max-width: 425px){
    .mapseat .main .list-row .row .rowseat .seat {
        width: 11px;
        height: 11px;
    }

    .mapseat .main .list-row .row .rowseat .right {
        width: 176px;
    }

    .mapseat .main .list-row .row .rowseat .left {
        width: 154px;
    }

    .mapseat .main .list-row .row .rowseat .seatnum {
        width: 11px;
    }

    .mapseat .note .item div {
        height: 11px;
        width: 11px;
    }
    
    .mapseat .note .item {
        font-size: 11px;
    }

    .mapseat .main .list-row .row .rowseat .seatnum {
        font-size: 7px;
    }

    .mapseat .main .list-row .row {
        height: 11px;
    }
}
