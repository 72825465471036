.mdyt10{
    height: 100vh; 
    position: relative; 
    background-color: black;
    z-index: -1;
}

.mdyt10 .back{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.mdyt10 .back img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.mdyt10 .inner{
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: end;
    padding: 0 50px;
}

.mdyt10 .content{
    height: auto;
    width: 408px;
    border-radius: 10px;
    background-image: url('./images/box.png');
    background-size: cover;
    background-position: center;
    padding: 10px 20px;
    position: relative;
    margin-bottom: 50px;
}

.mdyt10 .content .logo{
    height: 100px;
    margin-bottom: 20px;
    margin-left: 40px;
}

.mdyt10 .content .logo img{
    height: 100%;
}

.mdyt10 .content p{
    background-color: white;
    border-radius: 8px;
    padding: 10px 20px;
    line-height: 1.2;
    margin-bottom: 30px;
}

.mdyt10 .content .mount{
    position: absolute;
    height: 160px;
    bottom: -95px;
    left: -56px;
}

.mdyt10 .content .mount img{
    height: 100%;
}

@media screen and (max-width: 768px){
    .mdyt10 .inner{
        padding: 0 20px;
    }

    .mdyt10 .content {
        width: 80%;
        max-width: 340px;
    }

    .mdyt10 .content .logo{
        height: 70px;
    }

    .mdyt10 .content p {
        font-size: 12px;
    }
}

@media screen and (max-width: 425px){
    .mdyt10 .content .logo{
        height: 56px;
    }
}
