.booking{
    height: 100vh;
    background-image: url('./images/back.png');
    background-size: cover;
}

.booking .inner{
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 70px;
    display: flex;
    justify-content: center;
    align-items: start;
    overflow-y: auto;
}

.booking .sub-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1024px;
    width: 1124px;
    padding: 20px;
}

@media screen and (max-width: 1024px){
    .booking .sub-inner{
        max-width: 1124px;
        min-width: 375px;
        width: 100%;
        flex-flow: column-reverse;
        gap: 50px;
        justify-content: start;
        padding: 20px 10px;
    }
}

@media screen and (max-width: 375px){
    .booking .inner{
        justify-content: start;
    }
}