.mainvisual{
    height: 100vh;
    background-image: url('../../images/background.png');
    background-size: cover;
    position: relative
}

.mainvisual .content{
    display: flex;
    justify-content: space-between;
    padding: 0 80px;
}

.mainvisual .image{
    position: relative;
    margin-top: 100px;
    z-index: 2;
}

.mainvisual .image figure{
    position: absolute;
    height: 220px;
    width: 330px;
    border: 10px solid white;
    overflow: hidden;
}

.mainvisual .image figure img{
    width: 100%;
}

.mainvisual .image figure.image1{
    top: 78.82px;
    left: 5px;
    transform: rotate(-15deg);
}

.mainvisual .image figure.image2{
    top: 206px;
    left: 230.85px;
    transform: rotate(11deg);
}

.mainvisual .image figure.image3{
    top: 354.57px;
    left: 36px;
    transform: rotate(-2deg);
    z-index: 2;
}

.mainvisual .content .text{
    width: 695px;
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: end;
    padding-top: 186px;
    z-index: 2;
}

.mainvisual .content .text figure{
    width: 100%;
    margin-bottom: 20px;
}

.mainvisual .content .text figure img{
    width: 100%;
}

.mainvisual .content .text p{
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-right: 30px;
}

.mainvisual .mountant{
    position: absolute;
    height: 700px;
    bottom: -280px;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    animation: 3s ease-in-out 1s infinite alternate mount;
    z-index: 1;
}

.mainvisual .mountant img{
    height: 100%;
}

@keyframes mount{
    from{
        bottom: -280px;
    }
    to{
        bottom: -270px;
    }
}

.mainvisual .cloud{
    position: absolute;
    height: 300px;
    bottom: 180px;
    left: 0px;
    animation: 3s ease-in-out 1s infinite alternate cloud;
    z-index: 2;
}

.mainvisual .cloud img{
    height: 100%;
}

@keyframes cloud{
    from{
        left: 0px;
    }
    to{
        left: 20px;
    }
}

@media screen and (max-width: 1440px){
    .mainvisual .content{
        padding: 0 20px;
    }

    .mainvisual .content .text{
        width: 520px;
        padding-top: 265px;
    }

    .mainvisual .content .text p{
        font-size: 20px;
    }

    .mainvisual .mountant {
        height: 600px;
    }

    .mainvisual .cloud {
        bottom: 105px;
        height: 240px;
    }

    .mainvisual .image figure{
        height: 178px;
        width: 270px;
    }

    .mainvisual .image figure.image2{
        left: 147px;
    }
}

@media screen and (max-width: 1024px){
    .mainvisual .image{
        opacity: 0;
    }

    .mainvisual .content .text{
        padding-top: 168px;
    }

    .category .title .top {
        height: 40px;
        overflow: hidden;
    }

    .category .title .top::after{
        bottom: 0px;
    }
}

@media screen and (max-width: 768px){

    .mainvisual .image{
        display: none;
    }

    .mainvisual .content .text {
        height: auto;
        width: 400px;
    }

    .mainvisual .cloud {
        bottom: 15px;
    }
}

@media screen and (max-width: 425px){
    .mainvisual .content .text p{
        font-size: 14px;
    }
}