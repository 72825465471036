.category{
    height: 100vh;
    background-image: url('./images/mdyt.png');
    background-size: cover;
    position: relative;
}

.category .title{
    position: absolute;
    width: 100vw;
    display: flex;
    flex-flow: column;
    align-items: center;
    z-index: 2;
    margin-top: 60px;
}

.category .title .top{
    display: flex;
    gap: 10px;
    position: relative;
}

.category .title .top::after{
    content: "";
    width: 100%;
    height: 2px;
    background-color: black;
    position: absolute;
    left: 0;
    bottom: 10px;

}

.category .title .top figure{
    width: 70px;
}

.category .title .top figure img{
    width: 100%;
}

.category .title .top p{
    font-size: 40px;
    font-weight: bold;
}

.category .title .bottom{
    display: flex;
    gap: 5px;
    align-items: center;
}

.category .title .bottom figure{
    height: 60px;
}

.category .title .bottom figure.t10{
    height: 35px;
}

.category .title .bottom figure img{
    height: 100%;
}

.category .title .bottom p{
    font-size: 32px;
    font-weight: bold;
}

.category .cover_grid{
    width: 100vw;
    height: 100vh;
    padding: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: end;
}

.category .grid{
    max-width: 1122px;
    height: 544px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    border: 5px solid white;
    list-style: none;
    z-index: 1;
}

.category .grid li{
    position: relative;
    border: 5px solid white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category .grid li img{
    height: auto;
    width: 100%;
    transition: transform ease-in-out 0.25s, opacity ease-in-out 0.25s;
}

.category .grid li:hover img{
    transform: scale(1.2);
    opacity: 0.7;
}

.category .grid .i1{
    grid-column: 1;
    grid-row: 1 / 3;
}

.category .grid .i2{
    grid-column: 2 / 4;
    grid-row: 1;
}

.category .grid .i5{
    grid-column: 3;
    grid-row: 2 / 4;
}

.category .grid li p{
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: bold;
    opacity: 0;
    transition: opacity ease-in-out 0.25s;
    padding: 5px;
    border-radius: 4px;
    background-color: white;
}

.category .grid li:hover p{
    opacity: 1;
}

.category .grid li.choice img{
    transform: scale(1.2);
    opacity: 0.7;
}

.category .grid li.choice p{
    opacity: 1;
}

.category .cloud{
    position: absolute;
    bottom: -100px;
    height: 226px;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category .cloud img{
    height: 100%;
    width: auto;
}

@media screen and (max-width: 1440px){
    .category .grid{
        height: calc(55% + 100px);
    }
}

@media screen and (max-width: 768px){
    .category .title .top figure{
        width: 45px;
    }

    .category .title .top p{
        font-size: 26px;
    }

    .category .title .top{
        height: 30px;
    }

    .category .title .bottom p{
        font-size: 16px;
    }

    .category .title .bottom figure{
        height: 30px;
    }
    
    .category .title .bottom figure.t10{
        height: 16px;
    }

    .category .grid{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 1fr);
        border: 2px solid white;
        max-width: 450px;
    }

    .category .grid .i1{
        grid-column: 1;
        grid-row: 1/3;
    }
    
    .category .grid .i2{
        grid-column: auto;
        grid-row: auto;
    }
    
    .category .grid .i5{
        grid-column: 2;
        grid-row: 3/5;
    }

    .category .grid .i9{
        grid-column: 1/3;
        grid-row: auto;
    }

    .category .grid li p{
        font-size: 10px;
    }

    .category .grid li{
        border: 2px solid white;
    }
}

@media screen and (max-width: 425px){
    .category .title .bottom p{
        font-size: 12px;
    }
}

