footer{
    background-color: #2D5593;
    display: flex;
    justify-content: space-between;
    padding: 50px 80px;
    color: white;
}

footer .logo{
    height: 120px;
}

footer .logo img{
    height: 100px;
}

footer .clb,
footer .project{
    width: 35%;
}

footer .title{
    margin-bottom: 20px;
    position: relative;
}

footer .title p{
    font-size: 20px;
    font-weight: bold;
}

footer .title::after{
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -5px;
    left: 0;
    background-color: white;
}

footer .infor{
    display: flex;
    flex-flow: column;
    gap: 15px;
}

footer .infor .sub-title{
    font-weight: bold;
}

footer .infor .logo{
    height: 70px;
    display: flex;
    gap: 20px;
    padding-bottom: 15px;
}

footer .infor .logo figure{
    height: 100%;
}

footer .infor .logo figure img{
    height: 100%;
}

footer .clb .infor .contact{
    display: flex;
    gap: 10px;
    align-items: center;
}

footer .clb .infor .contact a{
    color: white;
    font-style: italic;
}

footer .project .infor .text{
    font-style: italic;
}

@media screen and (max-width: 1440px){
    footer{
        font-size: 14px;
    }

    footer .title p{
        font-size: 16px;
    }

    footer .clb .infor .contact a,
    footer .project .infor .text{
        font-size: 12px;
    }
}

@media screen and (max-width: 1024px) {
    footer{
        flex-flow: column;
        gap: 20px;
    }

    footer .clb,
    footer .project{
        width: 100%;
    }
}

@media screen and (max-width: 876px) {
    footer{
        padding: 50px 20px;
    }
}