header{
    height: 70px;
    width: 100vw;
    /* background-color: rgba(212, 237, 231, 0.6);
    box-shadow: 0 4px 4px rgba(255, 255, 255, 0.4); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
    transition: top ease-in-out 0.25s;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
}

header.hide {
    top: -70px;
}

body.top header {
    top: 0px !important;
}

header .header-left{
    display: flex;
}

header .header-left figure{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 1px solid white;
    overflow: hidden;
    cursor: pointer;
}

header .header-left figure img{
    height: 100%;
}

header .header-left ul{
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    list-style: none;
}

header .header-left ul li{
    margin-left: 50px;
    position: relative;
    cursor: pointer;
}

header .header-left ul li::after{
    content: "";
    width: 0%;
    height: 2px;
    background-color: #26BEC8;
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: width ease-in-out 0.25s;
}

header .header-left ul li.main::after{
    width: 100%;
}

header .header-left ul li:hover::after{
    width: 100%;
}

header .header-right{
    display: flex;
    gap: 10px;
}

header .header-right p{
    height: 50px;
    border-radius: 8px;
    background-color: rgba(38, 190, 200, 0.8);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color ease-in-out 0.25s;
    font-size: 14px;
    padding: 0 20px;
}

header .header-right p:hover{
    background-color: #26BEC8;
}

@media screen and (max-width: 768px){
    header .header-left nav{
        display: none;
    }

    header .header-right p{
        text-align: center;
        line-height: 1.2;
        padding: 0 10px;
    }
}

@media screen and (max-width: 425px){
    header{
        padding: 0 10px;
    }
}