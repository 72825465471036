.formbook{
    width: 500px;
}

.formbook .form,
.formbook .infor{
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    color: white;
    padding: 15px 30px;
    position: relative;
}

.formbook .infor{
    margin-bottom: 15px;
}

.formbook .title{
    font-size: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.2;
}

.formbook .title::after{
    content: "";
    position: absolute;
    width: 300px;
    height: 1px;
    background-color: white;
    bottom: 0;
}

.formbook .detail{
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.formbook .detail .item{
    display: flex;
    gap: 10px;
    align-items: center;
}

.formbook .detail .item figure{
    height: 24px;
} 

.formbook .detail .item figure img{
    height: 24px;
}

.formbook .infor .detail .item .sub-title{
    min-width: 80px;
}

.formbook .form .detail .item .sub-title{
    min-width: 110px;
}

.formbook .infor .detail .item .content{
    font-style: italic;
    font-weight: bold;
    line-height: 1.5;
}

.formbook .form .detail{
    margin-bottom: 15px;
}

.formbook .form .item fieldset{
    flex-grow: 1;
    width: 100%;
}

.formbook .form .item fieldset input{
    width: -webkit-fill-available;
    height: 30px;
    background-color: #d9d9d9;
    border-radius: 8px;
    padding: 5px 10px;
    border: none;
}

.formbook .form .note{
    font-size: 12px;
    font-style: italic;
    padding-left: 155px;
    margin-top: -10px;
}

.formbook .form .item div {
    height: 40px;
    width: 300px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0 10px;
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.formbook .form .item div::-webkit-scrollbar{
    height: 5px;
}

.formbook .form .item ul{
    display: flex;
    gap: 5px;
}

.formbook .form .item ul li{
    height: 25px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #26BEC8;
    color: white;
    font-weight: bold;
    transition: background-color ease-in-out 0.25s;
    cursor: pointer;
}

.formbook .form .item div ul li:hover{
    background-color: red;
}

.formbook .btn-submit{
    background-color: #26BEC8;
    width: 136px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 8px;
    justify-self: end;
    cursor: pointer;
    transition: background-color ease-in-out 0.25s;
}

.formbook .btn-submit:hover{
    background-color: #1b8b93;
}

.ant-message.ant-message-top{
    z-index: 9999;
}

.formbook .form .back-btn{
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 10px;
    border-radius: 8px;
    background-color: rgba(255, 0, 0, 0.485);
    color: white;
    cursor: pointer;
    transition: background-color ease-in-out 0.25s;
}

.formbook .form .clock{
    position: absolute;
    top: 20px;
    right: left;
    padding: 10px 10px;
    border-radius: 8px;
    background-color: rgba(255, 0, 0, 0.485);
    color: white;
    cursor: pointer;
    transition: background-color ease-in-out 0.25s;
}

.formbook .form.done{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: white;
    min-height: 350px;
}

.formbook .form.done .message{
    color: black;
    font-style: italic;
    line-height: 1.2;
}

.formbook .form .back-btn:hover{
    background-color: red;
}

#embedded-payment-container{
    height: 328px;
}

@media screen and (max-width: 768px){
    .formbook{
        max-width: 500px;
        width: 100%;
    }

    #embedded-payment-container {
        height: 585px;
    }
}

@media screen and (max-width: 425px){
    .formbook .form, .formbook .infor {
        padding: 15px 10px;
    }

    .formbook .detail {
        gap: 10px;
    }

    .formbook .detail .item .sub-title {
        font-size: 14px;
    }

    .formbook .infor .detail .item .content {
        font-size: 14px;
    }

    .formbook .infor .detail .item .sub-title {
        min-width: 66px;
    }

    .formbook .form .detail .item .sub-title {
        min-width: 70px;
    }

    .formbook .form .note {
        padding: 5px 15px;
    }
}