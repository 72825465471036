.aboutus{
    height: 100vh;
    background-image: url('./images/backback.png');
    background-size: cover;
    padding-top: 40px;
}

.aboutus .title{
    color: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 20px;
}

.aboutus .title .top{
    font-size: 24px;
    margin-bottom: 15px;
}

.aboutus .title .center{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
}

.aboutus .title .bottom{
    font-size: 20px;
    font-style: italic;
}

.aboutus .content{
    height: 579px;
    width: 887px;
    margin: 0 auto;
    position: relative;
}

.aboutus .content .main{
    height: 100%;
    width: 100%;
    border: 10px solid white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutus .content .main img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.aboutus .content .text{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    transition: opacity ease-in-out 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutus .content .text .text-inner{
    width: 492px;
}

.aboutus .content:hover .text{
    opacity: 1;
}

.aboutus .content .text figure{
    height: 48px;
    margin-bottom: 30px;
}

.aboutus .content .text figure img{
    height: 100%;
}

.aboutus .content .text p{
    line-height: 1.2;
}

.aboutus .content .cloud{
    position: absolute;
    width: 250px;
}

.aboutus .content .cloud img{
    width: 100%;
}

.aboutus .content .cloud.c1{
    bottom: -120px;
    left: -140px;
    animation: 3s ease-in-out 1s infinite alternate cloud1;
}

.aboutus .content .cloud.c2{
    bottom: -100px;
    right: 0;
    animation: 3s ease-in-out 1s infinite alternate cloud2;
}

@keyframes cloud1{
    from{
        left: -140px;
    }
    to{
        left: -120px;
    }
}

@keyframes cloud2{
    from{
        right: -100px;
    }
    to{
        right: -120px;
    }
}

@media screen and (max-width: 1440px){
    .aboutus .content{
        height: 467px;
        width: 792px;
    }
}

@media screen and (max-width: 1024px) {
    .aboutus .content {
        height: 406px;
        width: 626px;
    }
}

@media screen and (max-width: 768px) {
    .aboutus{
        padding: 30px 10px;
        height: auto;
        width: 100vw;
        overflow: hidden;
    }

    .aboutus .content {
        height: auto;
        max-width: 420px;
        width: 100%;
        display: flex;
        flex-flow: column;
        gap: 10px;
    }
    
    .aboutus .content .text{
        position: relative;
        height: 50%;
        opacity: 1;
        padding: 20px 30px;
    }

    .aboutus .title .top{
        font-size: 16px;
        margin-bottom: 15px;
    }
    
    .aboutus .title .center{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    
    .aboutus .title .bottom{
        font-size: 16px;
        font-style: italic;
    }

    .aboutus .content .text p {
        font-size: 12px;
    }

    .aboutus .content .text .text-inner {
        width: auto;
    }

    .aboutus .content .text figure {
        margin-bottom: 15px;
    }
}

